<template>
  <!-- eslint-disable  -->
  <div>
    <v-card flat class="w100p"></v-card>

    <div class="d-sm-flex mb-3 text-Heading6 Medium d-none">
      {{ $store.getters['app/getRouteTitle'] }}
    </div>

    <FilterBox
      :open.sync="isFilterOpen"
      v-model="filter"
      :loading="isLoading"
      :options="filterOptions"
      @search="fetchData"
    />

    <v-card
      outlined
      :color="$vuetify.breakpoint.smAndDown ? 'transparent' : null"
    >
      <v-card-text class="d-flex align-center pb-0 pb-sm-4">
        <v-spacer></v-spacer>
        <RefreshBtn :fetchData="fetchData" :loading="isLoading" />

        <SizeBox width="14" />
        <ExportBtn
          :items="result.list"
          :headers="headers"
          :file-name="`${
            $store.getters['app/getRouteTitle']
          } ${$moment().format('YYYY-MM-DD_H:mm:ss')}`"
          sheet-name="sheetName"
        />
      </v-card-text>
      <DataTable
        :list="result.list"
        :fields="headers"
        :total="result.total"
        :loading="isLoading"
        :replace="['created_at|formatTime']"
        @get-list="fetchData"
      ></DataTable>
    </v-card>
    <FunctionFab
      @info="isInfoOpen = true"
      @refresh="fetchData()"
      @open-filter="isFilterOpen = true"
      :no-filter="!filterOptions.length"
      :no-info="!Object.entries(result.header_info).length"
    />
  </div>
</template>

<script>
import { getSessionLogs } from '@/api/merchantCenter/sessionLogs';

export default {
  async created() {
    this.fetchData();
  },

  data() {
    return {
      ...this.$clone(this.$commonSchema),
      headers: [
        {
          text: '登入時間',
          value: 'created_at',
          dataFormat: this.$root.$options.filters.formatTime,
          align: 'center'
        },
        {
          text: '登入地點',
          value: 'location',
          align: 'center'
        },
        {
          text: 'IP',
          value: 'ip',
          align: 'center'
        },
        {
          text: '操作人員',
          value: 'user.name',
          align: 'center'
        }
      ],
      filterOptions: [
        {
          label: '建立時間',
          type: 'timeSet',
          name: 'filter.created_at',
          range: true,
          withTime: true
        },
        {
          label: 'IP Address',
          type: 'text',
          name: 'filter.ip'
        }
      ]
    };
  },

  computed: {},

  methods: {
    async fetchData(condition = this.condition) {
      this.isLoading = true;
      this.condition = condition;

      const SessionLogs = await getSessionLogs({
        ...condition,
        ...this.filter
      });

      if (!SessionLogs.error) {
        this.result.total = SessionLogs.total;
        this.result.page = SessionLogs.page;
        this.$set(
          this.result,
          'list',
          SessionLogs.items.map((item) => ({
            ...item
          }))
        );
      }

      this.isLoading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
